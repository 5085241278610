<!--
 * @Description: 添加回访服务单
 * @Author: ChenXueLin
 * @Date: 2021-10-14 15:02:42
 * @LastEditTime: 2022-05-11 10:02:42
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <el-form
                  :inline="true"
                  :model="reviewForm"
                  :rules="reviewFormRules"
                  ref="reviewForm"
                >
                  <el-form-item label="回访类型" prop="reviewTypeCode">
                    <e6-vr-select
                      v-model="reviewForm.reviewTypeCode"
                      :data="visiteTypeList"
                      placeholder="回访类型"
                      title="回访类型"
                      @change="handleChange"
                      clearable
                      :props="{
                        id: 'codeValue',
                        label: 'codeName'
                      }"
                    ></e6-vr-select>
                  </el-form-item>
                  <el-form-item label="指派处理人" prop="employeeId">
                    <e6-vr-select
                      v-model="reviewForm.employeeId"
                      :data="customerService"
                      placeholder="指派处理人"
                      title="指派处理人"
                      clearable
                      :props="{
                        id: 'employeeId',
                        label: 'userName'
                      }"
                    ></e6-vr-select>
                  </el-form-item>
                </el-form>
                <div class="right-button">
                  <el-button type="primary" @click="addItem">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="deleteItem">
                    删除任务项
                  </el-button>
                </div>
              </div>
              <el-table
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </template>
          <!-- 任务项设置   end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 初装 -->
    <first-install-visit
      :visible.sync="visible"
      @pushData="pushData"
      :reviewTypeCode="reviewForm.reviewTypeCode"
      v-if="reviewForm.reviewTypeCode == 3"
    ></first-install-visit>
    <!-- 维护回访 -->
    <maintenance-visit
      :visible.sync="visible"
      @pushData="pushData"
      :reviewTypeCode="reviewForm.reviewTypeCode"
      v-if="reviewForm.reviewTypeCode == 1"
    ></maintenance-visit>
    <!--客户满意度 -->
    <satisfaction-visit
      :visible.sync="visible"
      @pushData="pushData"
      :reviewTypeCode="reviewForm.reviewTypeCode"
      v-if="reviewForm.reviewTypeCode == 2"
    ></satisfaction-visit>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { findDownList, createReviewService, getListIdAndNames } from "@/api";
import { printError } from "@/utils/util";
import MaintenanceVisit from "./components/maintenanceVisit";
import satisfactionVisit from "./components/satisfactionVisit";
import FirstInstallVisit from "./components/firstInstallVisit.vue";
export default {
  name: "addVisitService",
  components: { MaintenanceVisit, satisfactionVisit, FirstInstallVisit },
  data() {
    return {
      loading: false,
      reviewForm: {
        reviewTypeCode: "",
        reviewTypeStr: "",
        employeeId: "",
        employeeName: ""
      },
      reviewFormRules: {
        reviewTypeCode: [
          {
            required: true,
            message: "请选择回访类型",
            trigger: ["blur", "change"]
          }
        ],
        employeeId: [
          {
            required: true,
            message: "请选择指派处理人",
            trigger: ["blur", "change"]
          }
        ]
      },
      customerService: [], //客服列表
      /*****问题类型********/
      reviewTypeCode: "", //回访类型值的code
      visiteTypeList: [], //回访类型下拉框
      /******任务项***********/
      columnData: [
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitedManName",
          display: true,
          fieldLabel: "联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitedManNumber",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selectColumn: [], //选中要删除的数据
      /*****添加任务项******/
      visible: false
    };
  },
  watch: {},
  mixins: [base],
  created() {
    this.initData();
  },
  methods: {
    //更改类型
    handleChange() {
      this.tableData = [];
    },
    async initData() {
      try {
        this.loading = true;
        let promiseList = [
          findDownList(["RevisitType"]),
          getListIdAndNames({ id: 3 })
        ];
        let [visiteTypeRes, customerServiceRes] = await Promise.all(
          promiseList
        );
        this.visiteTypeList = this.getFreezeResponse(visiteTypeRes, {
          path: "data.RevisitType"
        });
        //客服列表
        this.customerService = this.getFreezeResponse(customerServiceRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********任务项**********/
    handleSelectionChange(column) {
      this.selectColumn = column.map(item => item.sortNo);
    },
    //删除任务项
    deleteItem() {
      if (!this.selectColumn.length) {
        this.$message.warning("请选择要删除的数据");
        return;
      }
      this.tableData = this.tableData.filter(
        item => !this.selectColumn.some(ele => ele === item.sortNo)
      );
    },
    /******添加任务项***********/
    //添加任务项
    addItem() {
      if (!this.reviewForm.reviewTypeCode) {
        this.$message.warning("请先选择回访类型");
        return;
      }
      this.visible = true;
    },
    //点击确定
    pushData(selectData) {
      if (selectData.length) {
        this.tableData = _.cloneDeep(selectData).concat(
          _.cloneDeep(this.tableData)
        );
        var obj = {};
        this.tableData = this.tableData.reduce((item, next) => {
          obj[next.sortNo] ? "" : (obj[next.sortNo] = true && item.push(next));
          return item;
        }, []);
      }
    },
    //创建回访单
    submit() {
      this.$refs.reviewForm.validate(valid => {
        if (valid) {
          if (!this.tableData.length) {
            this.$message.warning("任务项不能为空");
            return;
          }
          this.createTaskReq();
        }
      });
    },
    //创建回访单请求
    async createTaskReq() {
      try {
        this.loading = true;
        let itemList = this.tableData.map(item => {
          return Object.assign(
            {},
            {
              fixId: item.fixId,
              corpName: item.corpName,
              corpId: item.corpId,
              contactName: item.revisitedManName,
              contactPhone: item.revisitedManNumber,
              sceneName: item.sceneName,
              revisitId: item.revisitId,
              revisitType: this.reviewForm.reviewTypeCode,
              issuccess: item.issuccess
            }
          );
        });
        let res = await createReviewService({
          ...this.reviewForm,
          reviewTypeStr: this.visiteTypeList.find(item => {
            return item.codeValue == this.reviewForm.reviewTypeCode;
          }).label,
          employeeName: this.customerService.find(item => {
            return item.employeeId == this.reviewForm.employeeId;
          }).userName,
          itemList
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.tableData = [];
          // this.closeTag(this.$route);
          // this.routerPush({
          //   name: "workOrderManage/visiteManage",
          //   params: {
          //     refresh: true
          //   }
          // });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 点击取消
    handleGoBack() {
      this.closeTag(this.$route);
      this.routerPush({
        name: "workOrderManage/visiteManage",
        params: {
          refresh: false
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}
.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}

.associated-info {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  .el-form {
    margin-right: 20px;
  }
}
</style>
